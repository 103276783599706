import { CSSProperties } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
export function Projects() {
    return <div className="main h-100 d-flex flex-column justify-content-center">
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    <h1 className="title" style={styles.title}>
                        PROJECTS
                    </h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Swiper
                        slidesPerView={1}
                        style={{position: "relative", zIndex: 101}}
                        centeredSlides={true}
                    >
                        <SwiperSlide className="text-center">
                            <iframe 
                                title="Hotel Project"
                                src="http://localhost/hotel/public/views/" 
                                style={{ width: '80vh', height: '60vh' }}>

                            </iframe>
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <iframe 
                                title="Hotel Project"
                                src="http://localhost:8000" 
                                style={{ width: 1920, height: 1080, maxWidth: "80vw", maxHeight:"60vh" }}>

                            </iframe>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    </div>
}

const styles: Record<string, CSSProperties> = {
    main: {
        position: "relative",
        backgroundColor: "transparent"
    },
    title: {
        fontFamily: "strasua",
        paddingBottom: 16,
        zIndex: 10,
        position: "relative"
    },  
    subtitle: {
        fontFamily: "chivo",
        zIndex: 10,
        position: "relative"
    },
    ellipse: {
        position: "absolute",
        bottom: 0,
        left: 0,
        transform: "translate(-30%, 30%)",
        zIndex: 1,
    }
}