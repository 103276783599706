import { CSSProperties } from "react";
import { Tag } from "../components/Tag";
import { ExperienceItem } from "../components/ExperienceItem";

export function Education() {
    return <div className="main h-100 d-flex flex-column justify-content-center">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h1 className="title pt-2" style={styles.title}>
                        EDUCATION
                    </h1>
                </div>
            </div>
            <div className="row pt-3 justify-content-center">
            <div className="col-12 pb-3">
                    <ExperienceItem
                        from={new Date("09/01/2018")}
                        to={new Date("09/01/2021")}
                        role={"Bachelor in Computer Sciences"}
                        organization={"Lebanese University"}
                        achievements={[]}
                    />

                </div>
                <div className="col-12 pb-3">
                    <ExperienceItem
                        from={new Date("07/01/2002")}
                        to={new Date("07/01/2017")}
                        role={"General Sciences"}
                        organization={"Mont La Salle"}
                        achievements={[]}
                    />
                </div>
            </div>
        </div>
    </div >
}

const styles: Record<string, CSSProperties> = {
    main: {
        position: "relative",
        backgroundColor: "transparent",
        textAlign: "center"
    },
    title: {
        textAlign: "center",
        fontFamily: "strasua",
        paddingBottom: 16,
    },
    subtitle: {
        fontFamily: "chivo",
        textAlign: "center"
    },
    ellipse: {
        position: "absolute",
        bottom: 0,
        left: 0,
        transform: "translate(-50%, 50%)"
    }
}