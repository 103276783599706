import { CSSProperties } from "react";
import { Tag } from "../components/Tag";

export function Skills() {
    const skills = [
        'JavaScript',
        'HTML5',
        'CSS',
        'PHP',
        'C/C++',
        'Java',
        'Python',
        'React / React-Native',
        'Bootstrap',
        'Laravel',
        'Springboot',
        'Wordpress',
        'MySQL',
        'Firebase',
        'Springboot',
        'Angular',
        'Groovy',
        'Jenkins',
        'MongoDB'
    ]
    return <div className="main h-100 d-flex flex-column justify-content-center">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h1 className="title" style={styles.title}>
                        SKILLS
                    </h1>
                    <p className="subtitle" style={styles.subtitle}>
                        I have personal / professional experience in all the below mentioned technologies
                    </p>
                </div>
            </div>
            <div className="row pt-3 justify-content-center">
                <div className="col-md-8 text-center">
                    {skills.map((item, index) =>
                        <Tag    
                            backgroundColor="#dedede15"
                            text={item}
                            key={index} />)}
                </div>
            </div>
        </div>
    </div>
}

const styles: Record<string, CSSProperties> = {
    main: {
        position: "relative",
        backgroundColor: "transparent",
        textAlign: "center"
    },
    title: {
        textAlign: "center",
        fontFamily: "strasua",
        paddingBottom: 16,
    },
    subtitle: {
        fontFamily: "chivo",
        textAlign: "center"
    },
    ellipse: {
        position: "absolute",
        bottom: 0,
        left: 0,
        transform: "translate(-50%, 50%)"
    }
}