import { CSSProperties } from "react";

export function About() {
    return <div className="main h-100 d-flex flex-column justify-content-center">
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    <h1 className="title" style={styles.title}>
                        ABOUT ME
                    </h1>
                    <div className="subtitle text-light" style={styles.subtitle}>
                        I have a background in web and mobile development, with experience in creating responsive and user-friendly applications.
                        I've transitioned into a full stack developer role, where I've been working in a microservice architecture.
                        <br />
                        <div className="d-flex justify-content-center justify-content-md-start">
                            <a
                                download
                                style={{ zIndex: 101 }}
                                href={`${process.env.PUBLIC_URL}/assets/nicolas-saikali-resume.pdf`}
                                className="mt-5 position-relatice button secondary d-block">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="title fs-5">
                                        GET RESUME
                                    </div>
                                    <div className="px-2"></div>
                                    <i className="fa fa-download text-light"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img style={styles.ellipse} src={`${process.env.PUBLIC_URL}/images/ellipse.svg`} alt="" />
    </div>
}

const styles: Record<string, CSSProperties> = {
    main: {
        position: "relative",
        backgroundColor: "transparent"
    },
    title: {
        fontFamily: "strasua",
        paddingBottom: 16,
        zIndex: 10,
        position: "relative"
    },
    subtitle: {
        fontFamily: "chivo",
        zIndex: 10,
        position: "relative"
    },
    ellipse: {
        position: "absolute",
        bottom: 0,
        left: 0,
        transform: "translate(-30%, 30%)",
        zIndex: 1,
    }
}