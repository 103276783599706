import { RefObject } from "react";
import ScrollSlider from "../components/ScrollSlider";
import moment from "moment";

export type ShowAroundHandlerOptions = {
    timeGap?: number,
    reset?: boolean,
}

export class ShowAroundHanlder {
    private static instance: ShowAroundHanlder | null = null
    private ref: RefObject<ScrollSlider> | undefined
    private n: number | undefined
    private id: NodeJS.Timeout | undefined
    private current: number = 0
    private running = false
    private slideChange: (newSlide?: number) => void = () => { }
    private options: ShowAroundHandlerOptions = {
        timeGap: 1000,
        reset: false,
    }

    static getInstance(): ShowAroundHanlder {
        if (!this.instance)
            this.instance = new ShowAroundHanlder()
        return this.instance
    }
    setOptions(options: ShowAroundHandlerOptions) {
        this.options = {
            ...this.options,
            ...options
        }
    }
    onSlideChange(callback: (newSlide?: number) => void): void {
        this.slideChange = callback
    }
    attach(ref: RefObject<ScrollSlider>): void {
        this.ref = ref
        this.n = ref?.current?.props?.slides?.length
    }
    run(): void {
        this.running = true
        this.ref?.current?.navigateToIndex(this.current++)
        this.id = setTimeout(() => {
            if (this.current < (this.n as number) && this.running) {
                this.slideChange?.(this.current)
                this.run()
            } else {
                if (this.options.reset)
                    this.ref?.current?.navigateToIndex(0)
            }
        }, this.options.timeGap)
    }
    stop(): void {
        clearTimeout(this.id)
        this.running = false
    }
    isRunning(): boolean {
        return this.running
    }
    getOptions() : ShowAroundHandlerOptions {
        return this.options
    }
}

export class VisitManager {
    private static dateFormat : string = "YYYY MM DD hh:mm:ss"
    static getLastVisit() {
        return localStorage.getItem('visited')
    }
    static didUserRegister(): boolean {
        return this.getLastVisit() !== null
    }
    static hasVisitedMore2Days(): boolean {
        if (this.didUserRegister() === true) {
            const lastVisit = parseInt(this.getLastVisit() as string)
            const today = Date.now()
            const diffInHours = (today - lastVisit) / (1000 * 60 * 24)
            return diffInHours >= 48
        } 
        return true
    }
    static registerVisit() {
        localStorage.setItem("visited", Date.now().toString())
    }
}