import { CSSProperties, RefObject, useRef } from "react";
import { ExperienceItem } from "../components/ExperienceItem";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType, Pagination } from "swiper";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export let swiperRef: any = null

export function Experience() {

    swiperRef = useRef<SwiperType>(null)

    return <div className="main h-100 d-flex flex-column justify-content-center">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h1 className="title pt-2" style={styles.title}>
                        EXPERIENCE
                    </h1>
                </div>
            </div>
            <div className="row pt-3 justify-content-center d-none d-md-flex">
                <div className="col-md-6 pb-3">
                    <ExperienceItem
                        from={new Date("07/17/2023")}
                        to={"Present"}
                        role={"Software Engineer"}
                        organization={"Murex"}
                        organizationWebsite={"https://www.murex.com/en"}
                        achievements={[
                            "Java, Springboot, Jenkins, Groovy, Python",
                            "Angular",
                        ]}
                    />
                </div>
                <div className="col-md-6 pb-3">
                    <ExperienceItem
                        from={new Date("07/01/2022")}
                        to={new Date("07/14/2023")}
                        role={"Software Engineer"}
                        organization={"ITXI"}
                        organizationWebsite={"https://www.itxi.net/"}
                        achievements={[]}
                    />

                </div>
                <div className="col-md-6 pb-3">
                    <ExperienceItem
                        from={new Date("03/01/2022")}
                        to={new Date("07/01/2022")}
                        role={"Full Stack Developer"}
                        organization={"TooEasy"}
                        organizationWebsite={"https://www.tooeasy.fr"}
                        achievements={[
                            "Wordress",
                            "React-Native",
                            "Website maintenance"
                        ]}

                    />
                </div>

                <div className="col-md-6 pb-3">
                    <ExperienceItem
                        from={new Date("07/01/2021")}
                        to={new Date("03/01/2022")}
                        role={"Software Engineer"}
                        organization={"ITXI"}
                        organizationWebsite={"https://www.itxi.net/"}
                        achievements={[
                            "CI/CD",
                            "TDD",
                            "React Native",
                            "Agile Environment"
                        ]}

                    />
                </div>
                <div className="col-md-6 pb-3">
                    <ExperienceItem
                        from={new Date("07/01/2021")}
                        to={new Date("03/01/2022")}
                        role={"Web Developer"}
                        organization={"HelloTree"}
                        organizationWebsite={"https://www.hellotree.co"}
                        achievements={[
                            "HTML, CSS, JS, PHP",
                            "Wordpress, Laravel",
                            "ReactJS, BootStrap",
                            "MySQL, Apache, CPanel"
                        ]}

                    />

                </div>


            </div>
            <div className="d-block d-md-none">
                <Swiper
                    ref={swiperRef}
                    style={{ height: 400 }}
                    slidesPerView={1}
                    modules={[Pagination]}
                    pagination={{ clickable: true }}
                >
                    <SwiperSlide className="container">
                        <div className="col-md-6 pb-3">
                            <ExperienceItem
                                from={new Date("07/17/2023")}
                                to={"Present"}
                                role={"Software Engineer"}
                                organization={"Murex"}
                                organizationWebsite={"https://www.murex.com/en"}
                                achievements={[
                                    "Java, Springboot, Jenkins, Groovy",
                                    "Angular",
                                ]}
                            />
                        </div>

                        <div className="col-md-6 pb-3">
                            <ExperienceItem
                                from={new Date("07/01/2022")}
                                to={new Date("07/14/2023")}
                                role={"Software Engineer"}
                                organization={"ITXI"}
                                organizationWebsite={"https://www.itxi.net/"}
                                achievements={[
                                    "CI/CD",
                                    "TDD",
                                    "React Native",
                                    "Agile Environment"
                                ]}
                            />

                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="container">
                        <div className="col-md-6 pb-3">
                            <ExperienceItem
                                from={new Date("03/01/2022")}
                                to={new Date("07/01/2022")}
                                role={"Full Stack Developer"}
                                organization={"TooEasy"}
                                organizationWebsite={"https://www.tooeasy.fr"}
                                achievements={[
                                    "Wordress",
                                    "React-Native",
                                    "Website maintenance"
                                ]}

                            />
                        </div>
                        <div className="col-md-6 pb-3">
                            <ExperienceItem
                                from={new Date("07/01/2021")}
                                to={new Date("03/01/2022")}
                                role={"Software Engineer"}
                                organization={"ITXI"}
                                organizationWebsite={"https://www.itxi.net/"}
                                achievements={[]}
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="container">
                        <div className="col-md-6 pb-3">
                            <ExperienceItem
                                from={new Date("07/01/2021")}
                                to={new Date("03/01/2022")}
                                role={"Web Developer"}
                                organization={"HelloTree"}
                                organizationWebsite={"https://www.hellotree.co"}
                                achievements={[
                                    "HTML, CSS, JS, PHP",
                                    "Wordpress, Laravel",
                                    "ReactJS, BootStrap",
                                    "MySQL, Apache, CPanel"
                                ]}

                            />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    </div >
}

const styles: Record<string, CSSProperties> = {
    main: {
        position: "relative",
        backgroundColor: "transparent",
        textAlign: "center"
    },
    title: {
        textAlign: "center",
        fontFamily: "strasua",
        paddingBottom: 16,
    },
    subtitle: {
        fontFamily: "chivo",
        textAlign: "center"
    },
    ellipse: {
        position: "absolute",
        bottom: 0,
        left: 0,
        transform: "translate(-50%, 50%)"
    }
}