import React, { CSSProperties } from 'react'

export interface TagProps {
    text: string,
    backgroundColor: string
}

export function Tag({ text, backgroundColor }: TagProps) {
    return <div style={{ ...styles.tag, backgroundColor }}>
        <div style={styles.text}>
            {text}
        </div>
    </div>
}

const styles: Record<string, CSSProperties> = {
    tag: {
        padding: 8,
        borderRadius: 8,
        display: "inline-block",
        margin: "8px",
        position: "relative",
        backgroundColor: "#44444490",
    },
    overlay: {
        borderRadius: "inherit",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        filter: "blur(6px)",
        backgroundColor: "#dedede15",
        zIndex: -1,
    },
    text: {
        color: "white",
        fontFamily: "chivo-light",
        fontSize: 16
    }

}