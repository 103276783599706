import { CSSProperties } from "react";

export function Contact() {
    return <div className="main h-100 d-flex flex-column justify-content-center">
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-6">
                    <h1 className="title pt-0" style={styles.title}>
                        REACH OUT
                    </h1>
                </div>
                <div className="col-md-6">
                    <div className="d-flex align-items-center justify-content-start">
                        <i className="fa fa-phone text-light fs-2"></i>
                        <div className="px-2"></div>
                        <a style={styles.link} href="tel:0096176756987" className="text-light h3 text-decoration-none contact-items d-block">
                            76 756 987
                        </a>
                    </div>
                    <hr className="border-secondary" />
                    <div className="d-flex align-items-center justify-content-start">
                        <i className="fa fa-envelope text-light fs-2"></i>
                        <div className="px-2"></div>
                        <a style={styles.link} href="mailto:nicolassaikali99@gmail.com" className="text-light h3 text-decoration-none contact-items d-block">
                            nicolassaikali99@gmail.com
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <img style={styles.ellipse} src={`${process.env.PUBLIC_URL}/images/ellipse.svg`} alt="" />
    </div>
}

const styles: Record<string, CSSProperties> = {
    main: {
        position: "relative",
        backgroundColor: "transparent"
    },
    link: {
        position: "relative",
        zIndex: 1,
    },
    title: {
        fontFamily: "strasua",
        paddingBottom: 16,
        zIndex: 10,
        position: "relative"
    },
    subtitle: {
        fontFamily: "chivo",
        zIndex: 10,
        position: "relative"
    },
    ellipse: {
        position: "absolute",
        bottom: 0,
        right:0,
        transform: "translate(30%, 30%)",
        zIndex: 0,
    }
}