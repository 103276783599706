import React, { CSSProperties } from 'react'

export type ShowAroundProps = {
    onShowAroundPressed?: () => void,
}

export type ShowAroundState = {
    shown?: boolean
}

export default class ShowAround extends React.Component<ShowAroundProps, ShowAroundState>{

    constructor(props: ShowAroundProps) {
        super(props)
        this.state = {
            shown: false
        }
    }

    show() {
        this.setState({ shown: true })
    }

    hide() {
        this.setState({ shown: false })
    }

    render() {
        return <div 
                className="show-around" 
                style={{
                    ...styles.showAround,
                    transform: this.state.shown 
                    ? 'translate(0, -50%)'
                    : 'translate(110%, -50%)'
            }}>
            <img src={`${process.env.PUBLIC_URL}/images/waving-hand.png`} alt="this is a waving hand" style={styles.image} />
            <div className="py-3"></div>
            <h3 style={styles.text}>
                Let me show you around
            </h3>
            <div className="pt-4 d-flex flex-row w-100 align-items-center justify-content-center">
                <button className='button' style={styles.btnGo} onClick={() => { this?.props?.onShowAroundPressed?.() }}>
                    Let's go
                </button>
                <div className="px-2"></div>
                <button className='button' style={styles.btnNo} onClick={() => {this.hide()}}>
                    No, thank you
                </button>
            </div>
        </div>

    }
}

const styles: Record<string, CSSProperties> = {
    showAround: {
        zIndex: 100,
        position: "absolute",
        transition: "500ms",
        right: 0,
        top: '50%',
        transform: "translate(0, -50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 16,
        backgroundColor: "#222222",
        boxShadow: '5px 5px 10px #22222250'
    },
    image: {
        width: "30vw",
        height: "auto",
        maxWidth: 300
    },
    text: {
        fontFamily: "chivo",
        fontSize: "var(--ref-fs)",
        color: "white"
    },
    btnGo: {
        backgroundColor: "var(--primary)",
        border: "none",
        textTransform: "uppercase"
    },
    btnNo: {
        backgroundColor: "transparent",
        borderWidth: 0
    }
}