import React, { CSSProperties } from 'react'

export type ExperienceItemProps = {
    from: Date,
    to: Date | 'Present',
    role?: string,
    organization?: string,
    organizationWebsite?: string,
    achievements: string[]
}

export function ExperienceItem({
    from,
    to,
    role,
    organization,
    organizationWebsite,
    achievements
}: ExperienceItemProps) {
    return <>
        <div style={styles.container}>
            <div style={styles.overlay}></div>
            <div style={styles.header}>
                <div style={styles.headerLeft}>
                    <div style={styles.role}>
                        {role}
                    </div>
                    {
                        organization
                            ? (
                                <a href={organizationWebsite} target="_blank" style={styles.organization} rel="noreferrer">
                                    {organization}
                                </a>
                            )
                            : null
                    }

                </div>
                <div style={styles.headerRight}>
                    {from.getMonth() + 1}/{from.getFullYear()} - {
                        typeof to === 'string'
                            ? to
                            : to.getMonth() + 1 + '/' + to.getFullYear()}
                </div>
            </div>
            {
                achievements?.length > 0
                    ? (
                        <>
                            <div className="w-100 bg-secondary my-2" style={styles.divider}></div>
                            <ul style={styles.achievements}>
                                {achievements?.map((item, index) =>
                                    <li key={index} style={styles.achievement}>
                                        {item}
                                    </li>)}
                            </ul>
                        </>
                    )
                    : null
            }
        </div>
    </>
}

const styles: Record<string, CSSProperties> = {
    container: {
        width: "100%",
        padding: 16,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch",
        backgroundColor: "#44444490",
        borderRadius: 15,
        position: "relative",
        height: "100%",
        zIndex: 10
    },
    overlay: {
        borderRadius: "inherit",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        filter: "blur(6px)",
        backgroundColor: "#dedede15",
        zIndex: -1,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    headerLeft: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "space-between"
    },
    headerRight: {
        color: "white",
        fontFamily: "chivo",
        fontSize: 14,
        display: "flex",
        flexDirection: "row",
        justifyContent: "end"
    },
    role: {
        color: "white",
        fontSize: `calc(var(--ref-fs) * 1)`,
        fontFamily: "chivo-bold"
    },
    organization: {
        color: "#dddddd",
        fontSize: `calc(var(--ref-fs) * 0.9)`,
        fontFamily: "chivo"
    },
    achievements: {
        listStyle: "none",
        margin: 0,
        padding: 0.
    },
    achievement: {
        color: "white",
        fontSize: `calc(var(--ref-fs) * 0.7)`,
        fontFamily: "chivo-light"
    },
    divider: {
        height: 1
    },
    body: {
    },
    footer: {

    }
}