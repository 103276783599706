import React, { CSSProperties, Ref, useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import ScrollSliderOOP from './components/ScrollSlider';
import { About } from './screens/About';
import { Contact, Education, Experience, Projects, Skills, swiperRef } from './screens';
import { ScrollSliderSensitivity } from './types';
import { ShowAroundHanlder, VisitManager } from './helpers';
import ShowAround from './components/ShowAround';

function App() {
  const ref: Ref<ScrollSliderOOP> = useRef(null)
  const showAroundRef: Ref<ShowAround> = useRef(null)
  const [navHeight, setNavHeight] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [progress, setProgress] = useState(0)
  const showAroundHandler = ShowAroundHanlder.getInstance()

  const handleOnScroll = useCallback(() => {
    showAroundRef?.current?.hide()
    showAroundHandler.stop()
  }, [showAroundHandler])

  const handleShowAroundClicked = useCallback(() => {
    showAroundRef?.current?.hide()
    showAroundHandler.setOptions({ timeGap: 3000, reset: true })
    showAroundHandler.attach(ref)
    showAroundHandler.run()
  }, [showAroundHandler])

  useEffect(() => {
    if (VisitManager.hasVisitedMore2Days()) {
      VisitManager.registerVisit()
      setTimeout(() => {
        if (!showAroundRef.current?.state.shown) {
          localStorage.setItem('visited', 'true')
          showAroundRef?.current?.show()
          showAroundHandler.onSlideChange((index?: number) => {
            if (index === 1) {
              setTimeout(() => {
                swiperRef?.current?.swiper?.slideTo(1)
              }, ((showAroundHandler?.getOptions()?.timeGap as number) / 2))
              setTimeout(() => {
                swiperRef?.current?.swiper?.slideTo(0)
              }, ((showAroundHandler?.getOptions()?.timeGap as number)))
            }
          })
        }
      }, 500)
    }
  }, [showAroundHandler, showAroundRef])

  return (
    <div style={{ position: "relative", top: 0 }}>
      <nav style={styles.nav} onLoad={e => setNavHeight(e.currentTarget?.clientHeight)} className="d-flex justify-content-center justify-content-md-between flex-wrap p-1 p-md-3">

        <img
          src={`${process.env.PUBLIC_URL}/images/logo.svg`}
          height={50}
          alt=""
          style={{ height: (activeIndex === 0 ? 1 : 0.6) * 50, transition: "300ms" }}
        />

        <div className="d-flex justify-content-center justify-content-md-end text-center align-items-center" style={styles.links}>
          <div
            onClick={() => ref?.current?.navigateToIndex(0)}
            className='nav-link' style={{ ...styles.navItem, color: activeIndex === 0 ? "#0081C9" : "white", paddingLeft: 0 }}>
            ABOUT ME
          </div>
          <div
            className='nav-link'
            onClick={() => ref?.current?.navigateToIndex(1)}
            style={{ ...styles.navItem, color: activeIndex === 1 ? "#0081C9" : "white" }}>
            EXPERIENCE
          </div>
          <div
            onClick={() => ref?.current?.navigateToIndex(2)}
            className='nav-link' style={{ ...styles.navItem, color: activeIndex === 2 ? "#0081C9" : "white" }}>
            EDUCATION
          </div>
          <div
            onClick={() => ref?.current?.navigateToIndex(3)}
            className='nav-link' style={{ ...styles.navItem, color: activeIndex === 3 ? "#0081C9" : "white" }}>
            SKILLS
          </div>
          {/* <div
            onClick={() => ref?.current?.navigateToIndex(4)}
            className='nav-link' style={{ ...styles.navItem, color: activeIndex === 4 ? "#0081C9" : "white" }}>
            PROJECTS
          </div> */}
          <div
            onClick={() => ref?.current?.navigateToIndex(4)}
            className='nav-link' style={{ ...styles.navItem, color: activeIndex === 4 ? "#0081C9" : "white" }}>
            REACH OUT
          </div>
        </div>
      </nav>

      <ShowAround
        ref={showAroundRef}
        onShowAroundPressed={handleShowAroundClicked} />


      <div style={{ position: "relative", height: "100vh", width: "100vw" }}>
        <img
          style={{ top: `${progress * 100}%`, right: 10, rotate: `${progress * 90}deg` }}
          src={`${process.env.PUBLIC_URL}/images/cube.svg`}
          alt=""
          className="cube cube-1" />
        <img
          style={{ top: `${(progress - 0.1) * 100}%`, right: 15, rotate: `-${progress * 80}deg` }}

          src={`${process.env.PUBLIC_URL}/images/cube.svg`}
          alt=""
          className="cube cube-2" />
        <img
          style={{ top: `${(progress - 0.2) * 100}%`, right: 10, rotate: `${progress * 100}deg` }}
          src={`${process.env.PUBLIC_URL}/images/cube.svg`}
          alt=""
          className="cube cube-3" />

        <ScrollSliderOOP
          ref={ref}
          withProgress={true}
          onSlideChange={setActiveIndex}
          onProgress={setProgress}
          onScroll={handleOnScroll}
          onSwipe={handleOnScroll}
          sensitivity={ScrollSliderSensitivity.VERY_HIGH}
          animationOptions={{
            easing: 'linear'
          }}
          style={{ backgroundColor: "#222", marginRight: `${navHeight * 3}px` }}
          slides={[
            {
              children: <About />
            },
            {
              children: <Experience />
            },
            {
              children: <Education />
            },
            {
              children: <div className='position-relative h-100'>
                <img
                  src={`${process.env.PUBLIC_URL}/images/react.png`}
                  style={{
                    opacity: 0.4,
                    transition: "1s",
                    position: "absolute",
                    left: `${((progress - 0.75) * 100)}%`,
                    bottom: `${((progress - 0.75) * 100)}%`,
                  }}
                  width={200}
                  height={200}
                  alt=""
                />
                <Skills />
              </div>
            },
            // {
            //   children: <Projects/>
            // },
            {
              children: <Contact />
            }
          ]}
        />
      </div>
    </div>

  );
}


const styles: Record<string, CSSProperties> = {
  nav: {
    position: "absolute",
    backgroundColor: 'rgba(51,51,51,0.4)',
    padding: 16,
    top: 0,
    left: 0,
    width: "100vw",
    maxWidth: '100vw',
    display: "flex",
    alignItems: "center",
    zIndex: 101,
    flex: 1
  },
  links: {
    flexGrow: 1
  },
  navItem: {
    fontFamily: "strasua",
    fontSize: `calc(var(--ref-fs) * 0.75)`,
    paddingLeft: 24,
    color: "white"
  }
}


export default App;
